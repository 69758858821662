var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isProfileOpen
    ? _c(
        "div",
        {
          staticClass:
            "tw-z-10 tw-w-full tw-bg-white tw-flex tw-flex-col md:tw-flex-none lg:tw-absolute lg:tw-left-0 lg:tw-bottom-0 lg:tw-w-120 lg:tw-h-full"
        },
        [
          _c("div", { staticClass: "tw-relative tw-order-1" }, [
            _c("div", { staticClass: "tw-aspect-ratio-211/120" }, [
              _vm.objHasKey(_vm.industry, "image")
                ? _c("img", {
                    staticClass:
                      "tw-absolute tw-object-cover tw-w-full tw-h-full",
                    attrs: {
                      src: _vm.industry.image.sizes.large,
                      alt: _vm.industry.image.description
                    }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.industryIcon
              ? _c(
                  "div",
                  {
                    staticClass:
                      "tw-absolute tw-top-0 md:tw-top-auto md:tw-bottom-0 tw-left-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-w-24 md:tw-w-32 tw--mt-12 tw-h-24 md:tw-h-32 md:tw-mt-16 tw-ml-8 tw--mb-16 tw-bg-white tw-border-4 tw-border-gray-200 tw-rounded-full",
                    style: { color: _vm.industry.color }
                  },
                  [
                    _c("div", {
                      staticClass:
                        "tw-w-10 tw-h-10 md:tw-w-16 md:tw-h-16 tw-mt-2 company-cluster-industry-icon",
                      domProps: { innerHTML: _vm._s(_vm.industryIcon) }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.companies.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "tw-shadow-md tw-order-3 lg:tw--ml-1 lg:tw-absolute lg:tw-left-full lg:tw-w-48 lg:tw-translate-y-70 company-cluster-companies-flyout"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "tw-flex tw-py-4 tw-px-6 tw-items-center tw-justify-between tw-w-full tw-text-left tw-text-base md:tw-text-lg lg:tw-text-sm xl:tw-text-base company-cluster-companies-flyout-title",
                      on: {
                        click: function($event) {
                          _vm.showCompanies = !_vm.showCompanies
                        }
                      }
                    },
                    [
                      _vm._v("\n      Colleges\n      "),
                      _c(
                        "svg",
                        {
                          staticClass:
                            "tw-w-auto tw-h-4 tw-mt-1 tw-fill-current",
                          class: { "tw-rotate-180": _vm.showCompanies },
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 11.457 20"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M1.457 20l10-10-10-10L0 1.457 8.543 10 0 18.543z",
                              fill: "currentColor"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showCompanies
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "tw-py-4 tw-text-base md:tw-text-lg lg:tw-text-sm lg:tw-max-h-120 lg:tw-overflow-y-scroll xl:tw-text-base"
                        },
                        _vm._l(_vm.companies, function(company, index) {
                          return _c("li", { key: company.id }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "tw-block tw-py-2 tw-px-6 tw-leading-none tw-font-medium hover:tw-bg-brand-blue hover:tw-text-white",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.selectCompany(company)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(company.name))]
                            )
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tw-px-8 tw-my-2 md:tw-mt-6 tw-mb-10 tw-order-2" },
            [
              _c(
                "div",
                { staticClass: "tw-flex tw-justify-between md:tw-mb-4" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "tw-mt-2 tw-mb-4 tw-text-sm tw-font-bold tw-block hover:tw-text-brand-blue",
                      on: {
                        click: function($event) {
                          return _vm.closeProfile()
                        }
                      }
                    },
                    [_vm._v("< Filters")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "tw-mt-2 tw-mb-4 tw-text-sm tw-font-bold tw-block hover:tw-text-brand-blue",
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [_vm._v("Next >")]
                  )
                ]
              ),
              _vm._v(" "),
              _c("h3", {
                staticClass: "company-cluster-industry-title",
                class: { "long-text": _vm.industry.name.length > 15 },
                domProps: { innerHTML: _vm._s(_vm.industry.name) }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "company-cluster-industry-description" }, [
                _vm._v(_vm._s(_vm.industry.description))
              ])
            ]
          ),
          _vm._v(" "),
          _vm.industry.facts_figures || _vm.industry.data
            ? _c(
                "tabs",
                {
                  staticClass:
                    "tw-mt-6 md:tw-mt-0 md:tw-absolute tw-inset-x-0 tw-bottom-0 tw-overflow-hidden tw-h-60 tw-order-4",
                  attrs: { onSelect: _vm.onChanged }
                },
                [
                  _c("template", { slot: "factsFigures" }, [
                    _c("span", { style: { borderColor: _vm.industry.color } }, [
                      _vm._v("Facts & Figures")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.industry.facts_figures
                    ? _c(
                        "tab",
                        {
                          staticClass: "tw-pt-10",
                          attrs: { "title-slot": "factsFigures" }
                        },
                        [
                          _c(
                            "ul",
                            {
                              ref: "tabScroll",
                              staticClass:
                                "tw-px-8 tw-mr-8 tw-overflow-y-scroll tw-max-h-34",
                              style: _vm.scrollColor
                            },
                            _vm._l(_vm.industry.facts_figures, function(
                              factFigure,
                              index
                            ) {
                              return _c("li", {
                                key: index,
                                staticClass:
                                  "tw-px-3 tw-py-2 tw-mb-5 tw-font-serif tw-text-xl tw-leading-tight tw-font-light tw-bg-white",
                                style: { borderColor: _vm.industry.color },
                                domProps: { innerHTML: _vm._s(factFigure) }
                              })
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("template", { slot: "data" }, [
                    _c("span", { style: { borderColor: _vm.industry.color } }, [
                      _vm._v("Data")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.industry.data
                    ? _c(
                        "tab",
                        {
                          staticClass: "tw-pt-10",
                          attrs: { "title-slot": "data" }
                        },
                        [
                          _c(
                            "ul",
                            {
                              ref: "tabScroll",
                              staticClass:
                                "tw-px-8 tw-mr-8 tw-overflow-y-scroll tw-max-h-34",
                              style: _vm.scrollColor
                            },
                            _vm._l(_vm.industry.data, function(data, index) {
                              return _c("li", {
                                key: index,
                                staticClass:
                                  "tw-px-3 tw-py-2 tw-mb-5 tw-font-serif tw-text-xl tw-leading-tight tw-font-light tw-bg-white",
                                style: { borderColor: _vm.industry.color },
                                domProps: { innerHTML: _vm._s(data) }
                              })
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (!_vm.isMobile && !_vm.isIndustryProfileOpen) ||
    (_vm.isMobile && !_vm.isProfileOpen && !_vm.isIndustryProfileOpen)
    ? _c(
        "div",
        {
          staticClass:
            "tw-z-10 tw-w-full tw-h-full tw-bg-white md:tw-py-8 lg:tw-absolute lg:tw-left-0 lg:tw-bottom-0 lg:tw-w-120 lg:tw-py-2 xl:tw-w-144 xl:tw-py-6"
        },
        [
          _c(
            "div",
            {
              staticClass: "tw-px-6 tw-pb-20 md:tw-pb-8 lg:tw-pb-0 lg:tw-px-12"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "tw-w-full tw-block tw-my-0 tw-text-base tw-font-semibold tw-text-white tw-py-7 tw-bg-brand-blue tw-shadow-custom tw--translate-y-1/2 md:tw-w-80 md:tw-mx-auto md:tw--mt-8 lg:tw-hidden",
                  on: {
                    click: function($event) {
                      return _vm.showFilters()
                    }
                  }
                },
                [_vm._v("Filter")]
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "company-cluster-intro-sub-title" }, [
                _vm._v(_vm._s(_vm.introMeta.sub_title))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "company-cluster-intro-overview" }, [
                _vm._v(_vm._s(_vm.introMeta.overview))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "tw-w-full tw-my-6 tw-text-base tw-font-semibold tw-text-white tw-py-7 tw-bg-brand-green tw-btn tw-hidden tw-shadow-custom"
                },
                [_vm._v("Add Your Company")]
              )
            ]
          ),
          _vm._v(" "),
          _c("filters")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import debounce from "lodash/debounce";

export default {
  computed: {
    companies() {
      const activeIndustry = this.activeIndustry,
        activeTag = this.activeTag;

      let companies = this.$store.state.companies;

      if (activeIndustry) {
        // Filter companies based on industry term ids
        companies = filter(companies, company => {
          return company.filter.includes(activeIndustry.term_id);
        });

        // Sort companies by name
        return orderBy(companies, "name", "asc");
      } else if (activeTag) {
        // Filter companies based on tag term ids
        companies = filter(companies, company => {
          return company.tags.includes(activeTag.term_id);
        });
      }

      return companies;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    activeTag() {
      return this.$store.state.activeTag;
    },
    activeIndustry() {
      return this.$store.state.activeIndustry;
    },
    industryIcon() {
      return this.$store.state.introMeta.industry_icon;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  methods: {
    selectCompany(company) {
      this.$store.commit("setActiveCompany", company);
      this.$store.commit("setProfileState", true);

      if (this.isMobile) {
        this.$store.commit("setIndustryProfileState", false);
      }
    },
    getCompanyIndustry(company) {
      if (this.objHasKey(company, "filter")) {
        return company.filter[0]; // First array item
      } else {
        console.error(
          "Company " + company.name + " is not attached to an industry."
        );
      }
    },
    getIndustryColor(id) {
      // Get hex code of industry using Id
      if (this.$store.state.taxonomies.filter) {
        const industry = this.$store.getters.getIndustryById(id);

        if (industry) {
          return industry.color;
        }
      } else {
        return "#000";
      }
    },
    resize() {
      var viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;

      if (viewportWidth < 1024) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
    objHasKey(obj, key) {
      let keys = Object.keys(obj);

      if (keys.length > 0) {
        if (keys.includes(key)) {
          return true;
        }
      }
    }
  },
  mounted() {
    window.addEventListener("resize", debounce(this.resize, 100));
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  created() {
    this.resize();
  }
};

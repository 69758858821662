var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company && _vm.isProfileOpen
    ? _c(
        "div",
        {
          staticClass:
            "tw-relative tw-z-10 tw-w-full md:tw-w-11/12 md:tw-absolute md:tw-right-0 md:tw-bottom-0 md:tw-mb-6 md:tw-mr-6 lg:w-auto lg:tw-max-w-124 lg:tw-mb-10 xl:tw-max-w-164 xl:tw-mr-10"
        },
        [
          _vm.companyCount > 1 || _vm.isMobile
            ? _c(
                "div",
                {
                  staticClass:
                    "tw-absolute tw-right-0 tw-flex tw-mr-4 md:tw-mr-0",
                  staticStyle: { top: "-4.5rem" }
                },
                [
                  _vm.isMobile
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "tw-px-2 tw-bg-brand-blue tw-text-white tw-mr-2",
                          on: {
                            click: function($event) {
                              return _vm.closeProfile()
                            }
                          }
                        },
                        [_vm._v("Back to Colleges")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("prev-next-buttons")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "tw-absolute tw-top-0 tw-right-0 tw-z-10 tw-mt-3 tw-mr-3 md:tw-mt-4 md:tw-mr-4",
              on: {
                click: function($event) {
                  return _vm.closeProfile()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "tw-w-4 tw-h-4",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 25.225 25.225"
                  }
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        "data-name": "Group 2659",
                        fill: "none",
                        stroke: "#33569b",
                        "stroke-width": "3.5"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "data-name": "Path 880",
                          d: "M23.988 1.237l-22.75 22.75"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          "data-name": "Path 881",
                          d: "M1.238 1.237l22.75 22.75"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "tw-relative tw-rounded-xl tw-bg-white md:tw-flex",
              style: _vm.borderColor(_vm.company)
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-items-center tw-w-full tw-border-r-2 tw-border-gray-200 md:tw-w-4/12"
                },
                [
                  _vm.company.logo
                    ? _c("img", {
                        staticClass:
                          "tw-max-w-72 tw-px-6 tw-mx-auto md:tw-max-w-full",
                        attrs: {
                          src: _vm.company.logo.url,
                          alt: _vm.company.name + " Logo"
                        }
                      })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-w-full tw-px-6 tw-border-t-2 tw-border-brand-gray tw-pt-6 tw-pb-16 md:tw-py-6 md:tw-w-8/12 md:tw-flex md:tw-flex-col md:tw-border-t-0"
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "tw-mb-4 tw-text-3xl tw-text-brand-blue tw-leading-none md:tw-pr-4 lg:tw-mb-2 lg:tw-text-2xl xl:tw-text-4xl"
                    },
                    [_vm._v(_vm._s(_vm.company.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "tw-text-base tw-mb-8 tw-leading-tight lg:tw-mb-4 lg:tw-text-xs xl:tw-text-base"
                    },
                    [_vm._v(_vm._s(_vm.company.description))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "md:tw-flex tw-mt-auto" }, [
                    _vm.company.website
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "tw-text-center tw-items-center tw-font-sans tw-text-brand-blue tw-rounded-lg tw-border-2 tw-border-brand-blue tw-uppercase tw-justify-center tw-block tw-my-6 tw-w-full tw-py-3 md:tw-mr-1 md:tw-inline-flex md:tw-w-5/12 md:tw-my-0 hover:tw-bg-brand-blue hover:tw-text-white",
                            attrs: {
                              href: _vm.company.website,
                              target: "_blank"
                            }
                          },
                          [_vm._v("Website")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.objHasKey(_vm.company, "social_media")
                      ? _c(
                          "div",
                          { staticClass: "tw-flex md:tw-w-5/12" },
                          [
                            _vm._l(_vm.company.social_media, function(
                              value,
                              name,
                              index
                            ) {
                              return [
                                value
                                  ? _c(
                                      "a",
                                      {
                                        key: index,
                                        staticClass:
                                          "tw-inline-flex tw-text-brand-blue tw-items-center tw-justify-center tw-border-2 tw-border-brand-blue tw-rounded-md tw-w-1/3 tw-py-3 md:tw-mx-1 tw-my-0 hover:tw-bg-brand-blue hover:tw-text-white",
                                        attrs: { href: value, target: "_blank" }
                                      },
                                      [
                                        _c("svg-sprite", {
                                          staticClass:
                                            "tw-w-auto tw-h-5 tw-mx-auto tw-fill-current",
                                          attrs: { href: "icon-" + name }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
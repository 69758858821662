<template>
  <div class="tw-relative">
    <!-- Google map -->
    <GmapMap
      :center="
        activeCompany
          ? activeCompany.position
          : { lat: 45.5132706, lng: -73.6498247 }
      "
      :zoom="activeCompany ? 17 : 13"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true
      }"
      map-type-id="roadmap"
      ref="mapRef"
      class="md:tw-h-180 lg:tw-h-196 tw-ml-auto company-cluster-map"
      :class="(isMobile && isIndustryProfileOpen || isMobile && activeCompany ? 'tw-h-90' : 'tw-h-140')"
    >
      <GmapMarker
        :key="index"
        v-for="(company, index) in mapCompanies"
        :position="company.position"
        :clickable="true"
        :title="company.name"
        :anchor="new google.maps.Point(0, 0)"
        :icon="{
          // path:
           //'M203.666,315.542a20.323,20.323,0,0,0-20.123,20.518c0,11.332,20.123,28.412,20.123,28.412s20.123-17.08,20.123-28.412A20.323,20.323,0,0,0,203.666,315.542Zm0,26.03a6.3,6.3,0,1,1,6.3-6.3A6.3,6.3,0,0,1,203.666,341.572Z',
         // path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',

          path:
            'M11,0.79C6.55,0.83,2.98,4.45,3.02,8.86	c0,4.46,7.98,12.05,7.98,12.05s7.98-7.59,7.98-12.05C19.02,4.45,15.45,0.83,11,0.79z M11,11.03c-1.38,0-2.5-1.12-2.5-2.5 s1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5l0,0C13.5,9.91,12.38,11.03,11,11.03z',
          strokeColor: 'transparent',
          strokeOpacity: 0,
          strokeWeight: 0,
          fillColor: getIndustryColor(getCompanyIndustry(company)),
          fillOpacity: 1,
          anchor: new google.maps.Point(11, 11),
          size: new google.maps.Size(22,22),
          scale: activeCompany === company ? 2.5 : 1.75
        }"
        @click="selectCompany(company)"
      />

      <!-- <gmap-circle
        v-if="activeCompany"
        :center="activeCompany.position"
        :radius="300"
        :options="{
          fillOpacity: 1,
          strokeWeight: 3,
          fillColor: '#fff'
        }"
      ></gmap-circle>-->
    </GmapMap>
  </div>
</template>

<script>
import Shared from "../Shared";
import filter from "lodash/filter";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "GoogleMap",
  mixins: [Shared],
  watch: {
    mapCompanies: function(companies) {
      this.$nextTick(() => {
        this.setMapBounds();
      });
    },
    activeCompany: function(company) {
      if (!company) {
        this.$nextTick(() => {
          this.setMapBounds();
        });
      }
    }
  },
  computed: {
    google: gmapApi,
    isIndustryProfileOpen() {
      return this.$store.state.isIndustryProfileOpen;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    markerIcon() {
      return this.$store.state.introMeta.marker;
    },
    mapCompanies() {
      return this.companies;
    }
  },
  methods: {
    setMapBounds() {
      this.$refs.mapRef.$mapPromise.then(map => {
        var bounds = new google.maps.LatLngBounds();

        for (let m of this.companies) {
          bounds.extend(m.position);
        }

        map.fitBounds(bounds);
      });

      // this.$refs.mapRef.$gmapDefaultResizeBus.$emit("resize");
    },
    markerHovered(index) {
      console.log("Marker Hovered! " + index);
    }
  }
};
</script>

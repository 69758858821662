<template>
  <div>
    <button
      v-if="companies.length > 1"
      class="tw-px-5 tw-py-4 tw-mr-2 tw-text-brand-blue tw-bg-white tw-border-2 tw-border-brand-blue hover:tw-bg-brand-blue hover:tw-text-white"
      @click="prev()"
      :class="{ 'tw-cursor-not-allowed': currentIndex == 0 }"
      :disabled="currentIndex == 0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="tw-w-auto tw-h-4 tw-rotate-180"
        viewBox="0 0 11.457 20"
      >
        <path d="M1.457 20l10-10-10-10L0 1.457 8.543 10 0 18.543z" fill="currentColor" />
      </svg>
    </button>
    <button
      v-if="companies.length > 1"
      class="tw-px-5 tw-py-4 tw-text-brand-blue tw-bg-white tw-border-2 tw-border-brand-blue hover:tw-bg-brand-blue hover:tw-text-white"
      @click="next()"
      :class="{
        'tw-cursor-not-allowed': currentIndex == companies.length - 1
      }"
      :disabled="currentIndex == companies.length - 1"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="tw-w-auto tw-h-4" viewBox="0 0 11.457 20">
        <path d="M1.457 20l10-10-10-10L0 1.457 8.543 10 0 18.543z" fill="currentColor" />
      </svg>
    </button>
  </div>
</template>

<script>
import Shared from "../Shared";

export default {
  name: "PrevNextButtons",
  mixins: [Shared],
  data() {
    return {
      currentIndex: null
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.currentIndex = this.companies.indexOf(this.activeCompany);
    });
  },
  methods: {
    prev() {
      this.currentIndex--;

      this.$nextTick(() => {
        this.$store.commit(
          "setActiveCompany",
          this.companies[this.currentIndex]
        );
      });
    },
    next() {
      this.currentIndex++;

      this.$nextTick(() => {
        this.$store.commit(
          "setActiveCompany",
          this.companies[this.currentIndex]
        );
      });
    }
  }
};
</script>

const axios = require("axios");

export default {
  state: {
    companies: [],
    taxonomies: [],
    introMeta: {},
    activeCompany: null,
    activeCompanyIndex: null,
    isProfileOpen: false,
    isIndustryProfileOpen: false,
    activeIndustry: null,
    showFilters: false,
    activeTag: null,
    isMobile: false
  },
  mutations: {
    setTaxonomies(state, data) {
      state.taxonomies = data;
    },
    setCompanies(state, data) {
      state.companies = data;
    },
    setIntroMeta(state, data) {
      state.introMeta = data;
    },
    setActiveCompany(state, data) {
      state.activeCompany = data;
      // state.activeCompanyIndex = data;
    },
    setProfileState(state, data) {
      state.isProfileOpen = data;
    },
    setActiveIndustry(state, data) {
      state.activeIndustry = data;
    },
    setIndustryProfileState(state, data) {
      state.isIndustryProfileOpen = data;
    },
    setShowFilters(state, data) {
      state.showFilters = data;
    },
    setActiveTag(state, data) {
      state.activeTag = data;
    },
    setMobile(state, data) {
      state.isMobile = data;
    }
  },
  getters: {
    getCompanyById: state => id => {
      return state.companies.find(item => item.id === id);
    },
    getIndustryById: state => id => {
      return state.taxonomies.filter.terms.find(item => item.term_id === id);
    }
  },
  actions: {
    getTaxonomies({ commit }) {
      // Get Taxonomies attached to post type
      axios
        .get(
          dciCompanyClusterJS.siteURL + "/wp-json/company-cluster/v1/taxonomies"
        )
        .then(response => {
          commit("setTaxonomies", response.data);

          return response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getCompanies({ commit }) {
      // Get Companies
      axios
        .get(
          dciCompanyClusterJS.siteURL + "/wp-json/company-cluster/v1/companies"
        )
        .then(response => {
          commit("setCompanies", response.data);

          console.log(response);

          return response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getIntroMeta({ commit }) {
      // Get Intro Meta
      axios
        .get(dciCompanyClusterJS.siteURL + "/wp-json/company-cluster/v1/intro")
        .then(response => {
          commit("setIntroMeta", response.data);

          return response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};

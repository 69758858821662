// Import Vue
import Vue from "vue";
import VueX from "vuex";
import Store from "./Store";
import CompanyCluster from "./vue/CompanyCluster.vue";
import WebFontLoader from "webfontloader";

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueX);

Vue.use(VueGoogleMaps, {
  load: {
    key: dciCompanyClusterJS.googleMapsAPIKey,

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true,
  },
});

(function () {
  // console.log(dciCompanyClusterJS.googleMapsAPIKey);

  new Vue({
    store: new VueX.Store(Store),
    el: "#company-cluster",
    components: {
      CompanyCluster,
    },
  });

  WebFontLoader.load({
    google: {
      families: ["Roboto:300,400,500,700"],
    },
    typekit: {
      id: "ugv4lfd",
    },
  });
})();

<template>
  <div
    v-if="isProfileOpen"
    class="tw-z-10 tw-w-full tw-bg-white tw-flex tw-flex-col md:tw-flex-none lg:tw-absolute lg:tw-left-0 lg:tw-bottom-0 lg:tw-w-120 lg:tw-h-full"
  >
    <div class="tw-relative tw-order-1">
      <div class="tw-aspect-ratio-211/120">
        <img
          v-if="objHasKey(industry, 'image')"
          :src="industry.image.sizes.large"
          :alt="industry.image.description"
          class="tw-absolute tw-object-cover tw-w-full tw-h-full"
        />
      </div>

      <!-- Industry Icon -->
      <div
        v-if="industryIcon"
        class="tw-absolute tw-top-0 md:tw-top-auto md:tw-bottom-0 tw-left-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-w-24 md:tw-w-32 tw--mt-12 tw-h-24 md:tw-h-32 md:tw-mt-16 tw-ml-8 tw--mb-16 tw-bg-white tw-border-4 tw-border-gray-200 tw-rounded-full"
        v-bind:style="{ color: industry.color }"
      >
        <div
          class="tw-w-10 tw-h-10 md:tw-w-16 md:tw-h-16 tw-mt-2 company-cluster-industry-icon"
          v-html="industryIcon"
        ></div>
      </div>

      <!-- Next Industry 
      <button
        class="tw-absolute tw-top-0 md:tw-top-auto md:tw-bottom-0 tw-right-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-w-16 tw-h-16 tw--mt-8 md:tw-w-20 md:tw-h-20 md:tw-mt-10 tw-mr-8 md:tw--mt-10 md:tw--mb-10 tw-text-white tw-bg-black tw-rounded-full tw-outline-none"
        @click="next()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="tw-w-auto tw-h-4" viewBox="0 0 11.457 20">
          <path d="M1.457 20l10-10-10-10L0 1.457 8.543 10 0 18.543z" fill="currentColor" />
        </svg>
      </button>-->
    </div>

    <!-- Companies fly out -->
    <div
      v-if="companies.length > 0"
      class="tw-shadow-md tw-order-3 lg:tw--ml-1 lg:tw-absolute lg:tw-left-full lg:tw-w-48 lg:tw-translate-y-70 company-cluster-companies-flyout"
    >
      <button
        @click="showCompanies = !showCompanies"
        class="tw-flex tw-py-4 tw-px-6 tw-items-center tw-justify-between tw-w-full tw-text-left tw-text-base md:tw-text-lg lg:tw-text-sm xl:tw-text-base company-cluster-companies-flyout-title"
      >
        Colleges
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-w-auto tw-h-4 tw-mt-1 tw-fill-current"
          :class="{ 'tw-rotate-180': showCompanies }"
          viewBox="0 0 11.457 20"
        >
          <path d="M1.457 20l10-10-10-10L0 1.457 8.543 10 0 18.543z" fill="currentColor" />
        </svg>
      </button>
      <ul
        v-if="showCompanies"
        class="tw-py-4 tw-text-base md:tw-text-lg lg:tw-text-sm lg:tw-max-h-120 lg:tw-overflow-y-scroll xl:tw-text-base"
      >
        <li v-for="(company, index) in companies" :key="company.id">
          <a
            href="#"
            @click.prevent="selectCompany(company)"
            class="tw-block tw-py-2 tw-px-6 tw-leading-none tw-font-medium hover:tw-bg-brand-blue hover:tw-text-white"
          >{{ company.name }}</a>
        </li>
      </ul>
    </div>

    <!-- Industry Name & Description -->
    <div class="tw-px-8 tw-my-2 md:tw-mt-6 tw-mb-10 tw-order-2">
      <div class="tw-flex tw-justify-between md:tw-mb-4">
        <button
          class="tw-mt-2 tw-mb-4 tw-text-sm tw-font-bold tw-block hover:tw-text-brand-blue"
          @click="closeProfile()"
        >&lt; Filters</button>

        <button
          class="tw-mt-2 tw-mb-4 tw-text-sm tw-font-bold tw-block hover:tw-text-brand-blue"
          @click="next()"
        >Next &gt;</button>
      </div>

      <h3
        class="company-cluster-industry-title"
        :class="{ 'long-text': industry.name.length > 15 }"
        v-html="industry.name"
      ></h3>
      <p class="company-cluster-industry-description">{{ industry.description }}</p>
    </div>

    <tabs
      v-if="industry.facts_figures || industry.data"
      :onSelect="onChanged"
      class="tw-mt-6 md:tw-mt-0 md:tw-absolute tw-inset-x-0 tw-bottom-0 tw-overflow-hidden tw-h-60 tw-order-4"
    >
      <!-- Facts & figures -->
      <template slot="factsFigures">
        <span v-bind:style="{ borderColor: industry.color }">Facts &amp; Figures</span>
      </template>

      <tab v-if="industry.facts_figures" class="tw-pt-10" title-slot="factsFigures">
        <ul
          class="tw-px-8 tw-mr-8 tw-overflow-y-scroll tw-max-h-34"
          :style="scrollColor"
          ref="tabScroll"
        >
          <li
            v-for="(factFigure, index) in industry.facts_figures"
            :key="index"
            v-html="factFigure"
            v-bind:style="{ borderColor: industry.color }"
            class="tw-px-3 tw-py-2 tw-mb-5 tw-font-serif tw-text-xl tw-leading-tight tw-font-light tw-bg-white"
          ></li>
        </ul>
      </tab>

      <!-- Data -->
      <template slot="data">
        <span v-bind:style="{ borderColor: industry.color }">Data</span>
      </template>

      <tab v-if="industry.data" class="tw-pt-10" title-slot="data">
        <ul
          class="tw-px-8 tw-mr-8 tw-overflow-y-scroll tw-max-h-34"
          ref="tabScroll"
          :style="scrollColor"
        >
          <li
            v-for="(data, index) in industry.data"
            :key="index"
            v-html="data"
            v-bind:style="{ borderColor: industry.color }"
            class="tw-px-3 tw-py-2 tw-mb-5 tw-font-serif tw-text-xl tw-leading-tight tw-font-light tw-bg-white"
          ></li>
        </ul>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { Tabs, Tab } from "vue-slim-tabs";
import Shared from "../Shared";

export default {
  name: "IndustryProfile",
  mixins: [Shared],
  components: {
    Tabs,
    Tab
  },
  data() {
    return {
      showCompanies: false
    };
  },
  computed: {
    isProfileOpen() {
      return this.$store.state.isIndustryProfileOpen;
    },
    industry() {
      return this.$store.state.activeIndustry;
    },
    scrollColor() {
      if (this.industry) {
        const color = this.industry.color;

        return {
          "--scroll-color": color
        };
      }
    }
  },
  methods: {
    onChanged() {
      const tabScroll = this.$refs["tabScroll"];

      tabScroll.scrollTop = 0;
    },
    closeProfile() {
      this.$store.commit("setIndustryProfileState", false);
      this.$store.commit("setActiveIndustry", null);
      this.$store.commit("setActiveCompany", null);
    },
    next() {
      this.$store.commit("setActiveCompany", null);

      const industries = this.$store.state.taxonomies.filter.terms,
        index = industries.indexOf(this.industry);

      if (index >= 0 && index < industries.length - 1) {
        this.$store.commit("setActiveIndustry", industries[index + 1]);
      } else {
        this.$store.commit("setActiveIndustry", industries[0]);
      }
    }
  }
};
</script>

<style scoped>
.vue-tabpanel ul::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  /* border-radius: 5px; */
  background-color: #50bda9;
}

.vue-tabpanel ul::-webkit-scrollbar-thumb {
  /* border-radius: 7px; */
  /* background-color: var(--scroll-color); */
  background-color: #228271;
  /* box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); */
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-relative" },
    [
      _c(
        "GmapMap",
        {
          ref: "mapRef",
          staticClass: "md:tw-h-180 lg:tw-h-196 tw-ml-auto company-cluster-map",
          class:
            (_vm.isMobile && _vm.isIndustryProfileOpen) ||
            (_vm.isMobile && _vm.activeCompany)
              ? "tw-h-90"
              : "tw-h-140",
          attrs: {
            center: _vm.activeCompany
              ? _vm.activeCompany.position
              : { lat: 45.5132706, lng: -73.6498247 },
            zoom: _vm.activeCompany ? 17 : 13,
            options: {
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: true
            },
            "map-type-id": "roadmap"
          }
        },
        _vm._l(_vm.mapCompanies, function(company, index) {
          return _c("GmapMarker", {
            key: index,
            attrs: {
              position: company.position,
              clickable: true,
              title: company.name,
              anchor: new _vm.google.maps.Point(0, 0),
              icon: {
                // path:
                //'M203.666,315.542a20.323,20.323,0,0,0-20.123,20.518c0,11.332,20.123,28.412,20.123,28.412s20.123-17.08,20.123-28.412A20.323,20.323,0,0,0,203.666,315.542Zm0,26.03a6.3,6.3,0,1,1,6.3-6.3A6.3,6.3,0,0,1,203.666,341.572Z',
                // path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',

                path:
                  "M11,0.79C6.55,0.83,2.98,4.45,3.02,8.86	c0,4.46,7.98,12.05,7.98,12.05s7.98-7.59,7.98-12.05C19.02,4.45,15.45,0.83,11,0.79z M11,11.03c-1.38,0-2.5-1.12-2.5-2.5 s1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5l0,0C13.5,9.91,12.38,11.03,11,11.03z",
                strokeColor: "transparent",
                strokeOpacity: 0,
                strokeWeight: 0,
                fillColor: _vm.getIndustryColor(
                  _vm.getCompanyIndustry(company)
                ),
                fillOpacity: 1,
                anchor: new _vm.google.maps.Point(11, 11),
                size: new _vm.google.maps.Size(22, 22),
                scale: _vm.activeCompany === company ? 2.5 : 1.75
              }
            },
            on: {
              click: function($event) {
                return _vm.selectCompany(company)
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.companies.length > 1
      ? _c(
          "button",
          {
            staticClass:
              "tw-px-5 tw-py-4 tw-mr-2 tw-text-brand-blue tw-bg-white tw-border-2 tw-border-brand-blue hover:tw-bg-brand-blue hover:tw-text-white",
            class: { "tw-cursor-not-allowed": _vm.currentIndex == 0 },
            attrs: { disabled: _vm.currentIndex == 0 },
            on: {
              click: function($event) {
                return _vm.prev()
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "tw-w-auto tw-h-4 tw-rotate-180",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 11.457 20"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d: "M1.457 20l10-10-10-10L0 1.457 8.543 10 0 18.543z",
                    fill: "currentColor"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.companies.length > 1
      ? _c(
          "button",
          {
            staticClass:
              "tw-px-5 tw-py-4 tw-text-brand-blue tw-bg-white tw-border-2 tw-border-brand-blue hover:tw-bg-brand-blue hover:tw-text-white",
            class: {
              "tw-cursor-not-allowed":
                _vm.currentIndex == _vm.companies.length - 1
            },
            attrs: { disabled: _vm.currentIndex == _vm.companies.length - 1 },
            on: {
              click: function($event) {
                return _vm.next()
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "tw-w-auto tw-h-4",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 11.457 20"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d: "M1.457 20l10-10-10-10L0 1.457 8.543 10 0 18.543z",
                    fill: "currentColor"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    v-if="!isMobile || (isMobile && showFilters)"
    class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-bg-white md:tw-h-auto md:tw-block md:tw-top-auto md:tw-left-auto md:tw-relative md:tw-mt-10"
  >
    <div class="tw-px-6 tw-pt-20 md:tw-pt-0 lg:tw-px-12">
      <button v-if="isMobile" class="tw-absolute tw-right-0 tw-top-0 tw-mt-24 tw-mr-6 tw-text-lg md:text-xl md:tw-mt-0 md:tw-mr-8" @click="closeFilters()">Close</button>

      <!-- Industries -->
      <div v-if="industries" class="tw-mt-4 md:tw--mt-10 lg:tw-mt-10">
        <div class="tw-flex tw-justify-between">
          <h5 class="company-cluster-filter-title">Filter by Type</h5>
        </div>

        <div class="tw-flex tw-flex-wrap tw-mt-2 tw-mb-6 company-cluster-filters">
          <div
            class="tw-relative tw-w-full tw-my-1-4 md:tw-px-1-4 md:tw-w-1/2"
            v-for="industry in industries.terms"
            :key="industry.id"
          >
            <button
              class="tw-flex tw-items-center tw-w-full tw-h-full tw-p-0 tw-m-0 company-cluster-filters-button"
              v-bind:style="styleObject(industry.color)"
              @click="selectIndustry(industry)"
            >
              <!-- Industry Icon -->
              <div
                v-if="industryIcon"
                class="tw-flex tw-items-center tw-justify-center tw-px-3 md:tw-px-4 tw-bg-white tw-border-2 tw-rounded-full tw-h-12 md:tw-h-17 tw-border-brand-dark-gray"
                v-bind:style="{ color: industry.color, 'margin-left': '-2px' }"
              >
                <div
                  class="tw-h-6 tw-w-6 md:tw-w-8 md:tw-h-8 company-cluster-industry-icon"
                  v-html="industryIcon"
                ></div>
              </div>
              <div
                v-html="industry.name"
                class="tw-inline-flex tw-text-left tw-font-sans tw-leading-snug tw-w-full tw-uppercase tw-text-base company-cluster-filters-button-text"
                :class="{ 'long-text': industry.name.length > 15 }"
              ></div>
            </button>
          </div>
        </div>
      </div>

      <!-- Tags -->
      <div v-if="objHasKey('tags', 'terms')" class="tw-w-full">
        <h5 class="tw-text-base tw-text-brand-blue tw-font-semibold md:tw-text-xl">Filter by Tag</h5>

        <div class="tw-flex tw-flex-wrap md:tw-my-2">
          <button
            class="tw-px-4 tw-py-2 tw-my-1 tw-mr-2 tw-text-base tw-font-semibold tw-bg-brand-dark-gray hover:tw-bg-black hover:tw-text-white"
            :class="{ active: activeTag == tag }"
            v-for="tag in tags.terms"
            :key="tag.id"
            @click="selectTag(tag)"
          >{{ tag.name }}</button>
        </div>
      </div>

      <button
        class="tw-w-full tw-mt-6 tw-text-base tw-font-semibold tw-text-white tw-py-7 tw-bg-brand-blue tw-btn tw-hidden tw-shadow-custom"
      >Filter</button>
    </div>
  </div>
</template>

<script>
import Shared from "../Shared";
import debounce from "lodash/debounce";

export default {
  name: "Filters",
  mixins: [Shared],
  computed: {
    showFilters() {
      return this.$store.state.showFilters;
    },
    industries() {
      return this.$store.state.taxonomies.filter;
    },
    tags() {
      return this.$store.state.taxonomies.company_tag;
    },
    activeTag() {
      return this.$store.state.activeTag;
    }
  },
  methods: {
    closeFilters() {
      this.$store.commit("setShowFilters", false);
    },
    clearData() {
      this.$store.commit("setActiveCompany", null);
      this.$store.commit("setActiveTag", null);
      this.$store.commit("setActiveIndustry", null);
    },
    selectIndustry(industry) {
      this.clearData();
      this.$store.commit("setShowFilters", false);
      this.$store.commit("setIndustryProfileState", true);
      this.$store.commit("setActiveIndustry", industry);
    },
    selectTag(tag) {
      this.clearData();
      this.$store.commit("setActiveTag", tag);
    },
    styleObject(color) {
      return {
        "--button-color--hover": color
      };
    }
  }
};
</script>

<style scoped>
.active {
  background-color: red !important;
}

.company-cluster-filters-button:hover {
  background-color: var(--button-color--hover);
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobile || (_vm.isMobile && _vm.showFilters)
    ? _c(
        "div",
        {
          staticClass:
            "tw-absolute tw-top-0 tw-left-0 tw-w-full tw-bg-white md:tw-h-auto md:tw-block md:tw-top-auto md:tw-left-auto md:tw-relative md:tw-mt-10"
        },
        [
          _c(
            "div",
            { staticClass: "tw-px-6 tw-pt-20 md:tw-pt-0 lg:tw-px-12" },
            [
              _vm.isMobile
                ? _c(
                    "button",
                    {
                      staticClass:
                        "tw-absolute tw-right-0 tw-top-0 tw-mt-24 tw-mr-6 tw-text-lg md:text-xl md:tw-mt-0 md:tw-mr-8",
                      on: {
                        click: function($event) {
                          return _vm.closeFilters()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.industries
                ? _c(
                    "div",
                    { staticClass: "tw-mt-4 md:tw--mt-10 lg:tw-mt-10" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-flex-wrap tw-mt-2 tw-mb-6 company-cluster-filters"
                        },
                        _vm._l(_vm.industries.terms, function(industry) {
                          return _c(
                            "div",
                            {
                              key: industry.id,
                              staticClass:
                                "tw-relative tw-w-full tw-my-1-4 md:tw-px-1-4 md:tw-w-1/2"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "tw-flex tw-items-center tw-w-full tw-h-full tw-p-0 tw-m-0 company-cluster-filters-button",
                                  style: _vm.styleObject(industry.color),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectIndustry(industry)
                                    }
                                  }
                                },
                                [
                                  _vm.industryIcon
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-flex tw-items-center tw-justify-center tw-px-3 md:tw-px-4 tw-bg-white tw-border-2 tw-rounded-full tw-h-12 md:tw-h-17 tw-border-brand-dark-gray",
                                          style: {
                                            color: industry.color,
                                            "margin-left": "-2px"
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "tw-h-6 tw-w-6 md:tw-w-8 md:tw-h-8 company-cluster-industry-icon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.industryIcon
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass:
                                      "tw-inline-flex tw-text-left tw-font-sans tw-leading-snug tw-w-full tw-uppercase tw-text-base company-cluster-filters-button-text",
                                    class: {
                                      "long-text": industry.name.length > 15
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(industry.name)
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.objHasKey("tags", "terms")
                ? _c("div", { staticClass: "tw-w-full" }, [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "tw-text-base tw-text-brand-blue tw-font-semibold md:tw-text-xl"
                      },
                      [_vm._v("Filter by Tag")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tw-flex tw-flex-wrap md:tw-my-2" },
                      _vm._l(_vm.tags.terms, function(tag) {
                        return _c(
                          "button",
                          {
                            key: tag.id,
                            staticClass:
                              "tw-px-4 tw-py-2 tw-my-1 tw-mr-2 tw-text-base tw-font-semibold tw-bg-brand-dark-gray hover:tw-bg-black hover:tw-text-white",
                            class: { active: _vm.activeTag == tag },
                            on: {
                              click: function($event) {
                                return _vm.selectTag(tag)
                              }
                            }
                          },
                          [_vm._v(_vm._s(tag.name))]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "tw-w-full tw-mt-6 tw-text-base tw-font-semibold tw-text-white tw-py-7 tw-bg-brand-blue tw-btn tw-hidden tw-shadow-custom"
                },
                [_vm._v("Filter")]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tw-flex tw-justify-between" }, [
      _c("h5", { staticClass: "company-cluster-filter-title" }, [
        _vm._v("Filter by Type")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    v-if="company && isProfileOpen"
    class="tw-relative tw-z-10 tw-w-full md:tw-w-11/12 md:tw-absolute md:tw-right-0 md:tw-bottom-0 md:tw-mb-6 md:tw-mr-6 lg:w-auto lg:tw-max-w-124 lg:tw-mb-10 xl:tw-max-w-164 xl:tw-mr-10"
  >
    <div
      v-if="companyCount > 1 || isMobile"
      class="tw-absolute tw-right-0 tw-flex tw-mr-4 md:tw-mr-0"
      style="top: -4.5rem;"
    >
      <button v-if="isMobile" class="tw-px-2 tw-bg-brand-blue tw-text-white tw-mr-2" @click="closeProfile()">Back to Colleges</button>

      <prev-next-buttons></prev-next-buttons>
    </div>

    <button class="tw-absolute tw-top-0 tw-right-0 tw-z-10 tw-mt-3 tw-mr-3 md:tw-mt-4 md:tw-mr-4" @click="closeProfile()">
      <svg xmlns="http://www.w3.org/2000/svg" class="tw-w-4 tw-h-4" viewBox="0 0 25.225 25.225">
        <g data-name="Group 2659" fill="none" stroke="#33569b" stroke-width="3.5">
          <path data-name="Path 880" d="M23.988 1.237l-22.75 22.75"/>
          <path data-name="Path 881" d="M1.238 1.237l22.75 22.75"/>
        </g>
      </svg>
    </button>

    <div class="tw-relative tw-rounded-xl tw-bg-white md:tw-flex" v-bind:style="borderColor(company)">
      <div class="tw-flex tw-items-center tw-w-full tw-border-r-2 tw-border-gray-200 md:tw-w-4/12">
        <img
          v-if="company.logo"
          class="tw-max-w-72 tw-px-6 tw-mx-auto md:tw-max-w-full"
          :src="company.logo.url"
          :alt="company.name + ' Logo'"
        />
      </div>

      <div
        class="tw-w-full tw-px-6 tw-border-t-2 tw-border-brand-gray tw-pt-6 tw-pb-16 md:tw-py-6 md:tw-w-8/12 md:tw-flex md:tw-flex-col md:tw-border-t-0"
      >
        <h4
          class="tw-mb-4 tw-text-3xl tw-text-brand-blue tw-leading-none md:tw-pr-4 lg:tw-mb-2 lg:tw-text-2xl xl:tw-text-4xl"
        >{{ company.name }}</h4>
        <p class="tw-text-base tw-mb-8 tw-leading-tight lg:tw-mb-4 lg:tw-text-xs xl:tw-text-base">{{ company.description }}</p>

        <div class="md:tw-flex tw-mt-auto">
          <a
            v-if="company.website"
            :href="company.website"
            target="_blank"
            class="tw-text-center tw-items-center tw-font-sans tw-text-brand-blue tw-rounded-lg tw-border-2 tw-border-brand-blue tw-uppercase tw-justify-center tw-block tw-my-6 tw-w-full tw-py-3 md:tw-mr-1 md:tw-inline-flex md:tw-w-5/12 md:tw-my-0 hover:tw-bg-brand-blue hover:tw-text-white"
          >Website</a>

          <div v-if="objHasKey(company, 'social_media')" class="tw-flex md:tw-w-5/12">
            <template v-for="(value, name, index) in company.social_media">
              <a
                v-if="value"
                :href="value"
                target="_blank"
                class="tw-inline-flex tw-text-brand-blue tw-items-center tw-justify-center tw-border-2 tw-border-brand-blue tw-rounded-md tw-w-1/3 tw-py-3 md:tw-mx-1 tw-my-0 hover:tw-bg-brand-blue hover:tw-text-white"
                :key="index"
              >
                <svg-sprite
                  :href="'icon-' + name"
                  class="tw-w-auto tw-h-5 tw-mx-auto tw-fill-current"
                ></svg-sprite>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrevNextButtons from "./PrevNextButtons.vue";
import Shared from "../Shared";
import SvgSprite from "./SVGSprite.vue";

export default {
  name: "CompanyProfile",
  mixins: [Shared],
  components: {
    PrevNextButtons,
    SvgSprite
  },
  computed: {
    companyCount() {
      return this.$store.state.companies.length;
    },
    company() {
      return this.$store.state.activeCompany;
    },
    isProfileOpen() {
      return this.$store.state.isProfileOpen;
    }
  },
  methods: {
    borderColor(company) {
      const industry = this.getCompanyIndustry(company),
        color = this.getIndustryColor(industry);

      return {
        borderColor: color
      };
    },
    // styleObject(company) {
    //   const industry = this.getCompanyIndustry(company),
    //     color = this.getIndustryColor(industry);

    //   return {
    //     backgroundColor: color
    //   };
    // },
    closeProfile() {
      this.$store.commit("setProfileState", false);
      this.$store.commit("setActiveCompany", null);

      if (this.isMobile && this.activeIndustry) {
        this.$store.commit("setIndustryProfileState", true);
      }
    }
  }
};
</script>

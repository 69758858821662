var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-relative dci-company-cluster" },
    [
      _c("google-map"),
      _vm._v(" "),
      _c("company-profile"),
      _vm._v(" "),
      _c("industry-profile"),
      _vm._v(" "),
      _c("intro")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
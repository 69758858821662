<template>
  <div
    v-if="(!isMobile && !isIndustryProfileOpen) || (isMobile && !isProfileOpen && !isIndustryProfileOpen)"
    class="tw-z-10 tw-w-full tw-h-full tw-bg-white md:tw-py-8 lg:tw-absolute lg:tw-left-0 lg:tw-bottom-0 lg:tw-w-120 lg:tw-py-2 xl:tw-w-144 xl:tw-py-6"
  >
    <div class="tw-px-6 tw-pb-20 md:tw-pb-8 lg:tw-pb-0 lg:tw-px-12">
      <button
        class="tw-w-full tw-block tw-my-0 tw-text-base tw-font-semibold tw-text-white tw-py-7 tw-bg-brand-blue tw-shadow-custom tw--translate-y-1/2 md:tw-w-80 md:tw-mx-auto md:tw--mt-8 lg:tw-hidden"
        @click="showFilters()"
      >Filter</button>

      <!-- <h1 class="company-cluster-intro-title">{{ introMeta.title }}</h1> -->

      <h2 class="company-cluster-intro-sub-title">{{ introMeta.sub_title }}</h2>

      <p class="company-cluster-intro-overview">{{ introMeta.overview }}</p>

      <button
        class="tw-w-full tw-my-6 tw-text-base tw-font-semibold tw-text-white tw-py-7 tw-bg-brand-green tw-btn tw-hidden tw-shadow-custom"
      >Add Your Company</button>
    </div>

    <!-- Filters -->
    <filters></filters>
  </div>
</template>

<script>
import Shared from "../Shared";
import Filters from "./Filters.vue";

export default {
  name: "Intro",
  mixins: [Shared],
  components: {
    Filters
  },
  computed: {
    isIndustryProfileOpen() {
      return this.$store.state.isIndustryProfileOpen;
    },
    isProfileOpen() {
      return this.$store.state.isProfileOpen;
    },
    introMeta() {
      return this.$store.state.introMeta;
    }
  },
  methods: {
    showFilters() {
      this.$store.commit("setShowFilters", true);
    }
  }
};
</script>

<template>
  <div class="tw-relative dci-company-cluster">
    <!-- Google map -->
    <google-map></google-map>

    <!-- Company profile -->
    <company-profile></company-profile>

    <!-- Industry profile -->
    <industry-profile></industry-profile>

    <!-- Intro side -->
    <intro></intro>
  </div>
</template>

<script>
import GoogleMap from "./GoogleMap.vue";
import Intro from "./Intro.vue";
import CompanyProfile from "./CompanyProfile.vue";
import IndustryProfile from "./IndustryProfile.vue";

export default {
  name: "CompanyCluster",
  components: {
    Intro,
    GoogleMap,
    CompanyProfile,
    IndustryProfile
  },
  created() {
    this.$store.dispatch("getTaxonomies");
    this.$store.dispatch("getCompanies");
    this.$store.dispatch("getIntroMeta");
  }
};
</script>
